@import "./stylingSchema.scss";

.contactForm {
    position: relative;
    margin: 0;
    padding: 30px 0;
    text-align: center;
    min-height: 70vh;
    align-items: center;
    z-index: 3;

    .outerBox {
        border: 2px solid $primary-green;
        border-radius: 15px;
        width: 80%;
        margin: auto;
        padding: 50px 80px;

        .contactInfo {

            ul {
                list-style: none;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-around;
                font-family: $font2;
                font-size: 1.2rem;
                padding: 0%;
                text-align: left;

                li {
                    margin: 0 20px;
                }

            }
        }

        input,
        textarea {
            display: block;
            width: 100%;
            margin: 30px auto;
            border: 1px solid $primary-green;
            border-radius: 5px;
            padding: 5px 8px;
            font-size: 1.2rem;
        }

        button {
            width: 250px;
            padding: 4px;
            font-family: $font2;
            font-size: 1.3rem;
            background: $primary-green;
            color: white;
            border: 1px solid $primary-green;
            border-radius: 15px;
            margin-top: 10px;

            &:hover {
                background: $secondary-green;
                border: 1px solid $secondary-green;
            }

            &:active,
            &:focus {
                outline: none;
                background: #112e37;
            }

        }
    }
}

@media all and (max-width: 835px) {
    .contactForm {
        .outerBox {
            .contactInfo {
                ul {
                    justify-content: start;
                }
            }
        }
    }
}