@import "./stylingSchema.scss";

.EBS-header {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
}

.navbar {
    padding: 8px 0 0 0 !important;
}

.navbar-nav {
    margin-right: 80px;

    a {
        color: white !important;
        font-family: $font1;
        font-size: 25px;
        // font-weight: bold;

        &:hover {
            text-decoration: underline;
        }
    }
}


.scroll {
    background-color: white;

    a {
        color: $primary-green !important;

        &:hover {
            color: #0f2830 !important;
            text-decoration: underline;
        }
    }
}

.nav-link {
    margin: 0 40px;
}

.EBS-logo {
    height: 65px;
    margin-left: 160px;
}

.navbar-toggler {
    margin: 0 40px 0 0;
    border: 1px solid white !important;

}

.navbar-light .navbar-toggler-icon {
    background-image: url("../assets/burger-icon.svg") !important;
}

.mobile {
    background-color: white;

    .navbar {
        .navbar-toggler {
            border: 1px solid $primary-green !important;

            .navbar-toggler-icon {
                background-image: url("../assets/burger-icon-black.svg") !important;
            }
        }
    }
}

// .navbar-toggler-icon-black {
//     background-image: url("../assets/burger-icon-black.svg") !important;
// }

.show {
    background: white;
    color: $primary-green;
    text-align: center;

    .navbar-nav {
        a {
            color: $primary-green !important;
        }
    }

    div {
        width: fit-content;
        margin: 0 auto;
    }
}

.navbar-toggler {

    &:focus,
    &:active {
        outline: none;
    }
}

@media all and (max-width: 935px) and (min-width: 815px) {
    .nav-link {
        margin: 0 20px;
    }
}

@media all and (max-width: 814px) {
    .nav-link {
        margin: 0 10px;
    }
}

@media all and (max-width: 760px) {
    .EBS-logo {
        height: 65px;
        margin: 0 60px;
    }
}