@import "./stylingSchema.scss";

.allWork {
    position: relative;
    margin: 70px 0 0 0;
    text-align: center;
    min-height: 70vh;
    z-index: 3;
}

.right {
    direction: rtl;
}