@import "./stylingSchema.scss";

.introSection {
    position: relative;
    min-height: 100vh;
    background: $green-gradient;
    padding: 40px 0 100px 0;
}

.text-content {
    width: 60%;
    margin: 11% auto 0 160px;
    text-align: left;
    color: white;

    h3 {
        font-family: $font1;
        font-size: 42px;
        font-weight: bold;
    }

    h5 {
        font-family: $font2;
        font-size: 21px;
    }

}

@media all and (max-width: 760px) {
    .text-content {
        width: 80%;
        margin: 11% 20px 0 60px;
    }

    @media all and (max-width: 550px) {
        .text-content {
            h3 {
                font-size: 35px;
            }

            h5 {
                font-size: 17px;
            }
        }
    }
}