@import "./stylingSchema.scss";

.achievementSection {
    position: relative;
    min-height: calc(100vh - 73px);
    background: $primary-green;
    padding: 40px 20px 15vh 20px;
    color: white;

    h2 {
        text-align: center;
        font-family: $font1;
        font-size: 45px;
        font-weight: bold;
    }

    .achievementsContainer {
        width: 80%;
        margin: 20px auto;
        font-family: $font2;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;

        .achievementCard {
            text-align: center;

            h4 {
                margin-bottom: 0;
                font-size: 1.5rem;
            }

            p {
                margin: 0 0 40px 0;
                font-size: 1.1rem;
            }
        }
    }
}


@media all and (max-width: 360px) {
    .achievementSection {
        h2 {
            font-size: 35px;
        }

        .achievementsContainer {
            .achievementCard {
                h4 {
                    font-size: 1.3rem;
                }

                p {
                    font-size: 1.0rem;
                }
            }
        }
    }

}