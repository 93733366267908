@import "./stylingSchema.scss";

.recentWorkSection {
    position: relative;
    margin: 0;
    text-align: center;
    min-height: 70vh;
    z-index: 3;
    padding-bottom: 80px;

    h3 {
        font-family: $font1;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0.1rem;
        color: $primary-green;
        margin-top: 0;
    }

    .carousel-item {
        transition: transform 3s ease-in-out;
    }

    .carousel-control-prev {
        width: 7vw;

        .carousel-control-prev-icon {
            background-image: url("../assets/left-arrow.svg");
            transform: scale(1.3);
        }
    }

    .carousel-control-next {
        width: 7vw;

        .carousel-control-next-icon {
            background-image: url("../assets/right-arrow.svg");
            transform: scale(1.3);
        }
    }

    .carousel-indicators {
        bottom: -80px;

        li {
            background-color: #5a828f;
            width: 3.5vw;
            height: 1vh;
        }

        .active {
            background-color: $secondary-green;
        }
    }
}

.workCard {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 90%;
    margin: 30px auto 60px auto;
    align-items: center;
    background: #f1f1f1;
    border-radius: 15px;
    padding: 30px 0;

    img {
        display: inline;
        width: 35vw;
        height: 20vw;
        border-radius: 15px;
    }

    .leftMobile {
        width: 18vw;
        transform: translateY(5px);
    }

    .text {
        width: 50%;
        text-align: left;
        vertical-align: middle;

        h2 {
            font-family: $font1;
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 0;
            color: $primary-green;
        }

        p {
            direction: ltr;
            font-family: $font2;
            font-size: 1.3rem;
            color: $primary-gray;
        }
    }
}

@media all and (max-width: 900px) {
    .workCard {
        flex-direction: column;

        img {
            display: inline;
            width: 45vw;
            height: 30vw;
            border-radius: 15px;
        }

        .photos {
            .mobile {
                width: 15vw;
            }
        }

        .text {
            margin-top: 20px;
            width: 75%;

            h2 {
                font-size: 30px;
            }

            p {
                text-align: justify;
                font-size: 1.1rem;
            }
        }
    }
}