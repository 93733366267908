@import "./stylingSchema.scss";

.services {
    position: relative;
    margin: 0;
    text-align: center;
    min-height: 100vh;
    z-index: 3;
    padding: 50px 0;

    h3 {
        font-family: $font1;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0.1rem;
        color: $primary-green;
        margin: 0 auto;
    }

    .services-container {
        border-radius: 10px;
        padding: 7px 2px;
        height: fit-content;
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        width: 98%;
        margin: 10px auto 0 auto;
        align-items: center;
        overflow: hidden;

        .serviceCard {
            width: 23vw;
            margin: 5px;
            height: 58vh;
            border-radius: 10px;
            text-align: center;
            padding: 20px 7px;
            margin: 10px 0px;
            border: 2px solid $primary-green;
            position: relative;
            display: flex;
            align-items: center;

            &:hover {
                box-shadow: -5px -5px 15px 5px #0f2930c4;
            }

            img {
                width: 6.5vw;
                margin: 0 auto 10px auto;
            }

            h3 {
                font-family: $font1;
                font-size: 23px;
                font-weight: bold;
                letter-spacing: 0.1rem;
                color: $primary-green;
            }

            p {
                margin-top: 5px;
                font-family: $font2;
                font-size: 18px;
                color: black;
            }
        }
    }

}

.react-multiple-carousel__arrow {
    background: #1d475493;
    min-width: 50px;
    min-height: 50px;

    &::before {
        font-size: 30px;
    }

    &:hover {
        background: $secondary-green;
    }

    &:focus,
    &:active {
        outline: white;
        border: white 2px inset;
    }
}

.react-multiple-carousel__arrow--left {
    left: 0.5vw;
}

.react-multiple-carousel__arrow--right {
    right: 0.1vw;
}

.carousel-container {
    padding-bottom: 50px;
    height: fit-content;
}

.react-multi-carousel-track {
    margin: 0 auto 0 calc(5vw);
    height: fit-content;
    vertical-align: middle;
    align-items: center;
    align-content: center;
}

.react-multi-carousel-dot button {
    width: 10px;
    height: 10px;
    border: 1px solid $primary-green;

    &:hover {
        box-shadow: 0px 0px 5px 1px rgba(114, 114, 114, 0.75);
    }
}

.react-multi-carousel-dot--active button {
    background: $secondary-green;
}



@media all and (max-width: 1050px) and (min-width: 315px) {
    .services {
        h3 {
            font-size: 25px;
        }

        .services-container {
            .serviceCard {
                width: 28vw;
                height: 65vh;
            }
        }
    }

    @media all and (max-width: 1018px) {
        .services {
            .services-container {
                .serviceCard {
                    width: 30vw;
                }
            }
        }

        .react-multi-carousel-track {
            margin: 0 auto 0 10vw;
        }
    }

    @media all and (max-width: 666px) {
        .services {
            .services-container {
                .serviceCard {
                    width: 70vw;
                    height: fit-content;
                }
            }
        }

        .carousel-container {
            padding-bottom: 10px;
        }

        .react-multi-carousel-track {
            margin: 0 auto 0 14vw;
        }

        .react-multiple-carousel__arrow {
            background: #1d475493;
            min-width: 30px;
            min-height: 30px;

            &::before {
                font-size: 16px;
            }
        }

        .react-multiple-carousel__arrow--left {
            left: 1vw;
        }
    }
}

//1018 byb2o 2
//647 byb2o 1 