@import "./stylingSchema.scss";

.footer {
    position: relative;
    margin-top: 30px;
    padding: 40px 90px 140px 90px;
    background: $primary-green;
    color: white;

    .footer-text {

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        div {
            margin: 0 20px;
        }

        h3 {
            font-family: $font1;
            font-weight: bold;
            margin-top: 0 20px;
        }

        ul {
            font-family: $font2;
            font-size: 1.1rem;
            text-decoration: none;
            list-style: none;
            padding: 0;
            line-height: 40px;

            li {
                a {
                    color: white;
                }
            }

            .phone {
                margin-left: 142px;
            }
        }

        .newsletter {
            input {
                background: $primary-green;
                border: white 1px solid;
                border-radius: 5px;
                padding: 3px 12px;
                color: white;
                margin-top: 6px;

                &::placeholder {
                    color: #ffffffa3;
                    font-family: $font2;
                    font-size: 1.1rem;
                    padding: 3px;
                }

                &:focus,
                &:active {
                    outline: white;
                    border: white 2px inset;
                }
            }
        }

        .social-div {
            margin: 0;

            p,
            a {
                color: white;
                margin-right: 10px;
            }
        }

        .social-icon {
            width: 25px;
            height: 25px;
            font-size: 1em;
        }
    }
}


@media all and (max-width: 550px) {
    .footer {
        padding: 40px 40px 140px 40px;

        h3 {
            margin-top: 30px;
        }
    }
}