/* serif */
//option 1: 'Peddana', serif
@import url('https://fonts.googleapis.com/css2?family=Peddana&display=swap');
//option 2: 'Zilla Slab', serif
@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@300&display=swap');

/* sans-serif */
//option 1: 'Mukta', sans-serif
@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');
//option 2: 'Varela Round', sans-serif
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');


/* colors */
$primary-green: #1D4754;
$primary-gray: #a0a0a0;

$secondary-green: #0f2930e5;
$secondary-gray: #C2C2C2;

$tertiary-gray: #242424;

/* fonts */
$font1: Helvetica Neue,
sans-serif;

$font2: 'Varela Round',
sans-serif;
// $font3: informal ( display or handwriting );

/* gradients */
$green-gradient: linear-gradient(30deg, #FFFFFF 0%, #1D4754 100%) 0% 0% no-repeat padding-box;








// body {
//     font-family: 'Varela Round', sans-serif;
//     font-size: 50px;
// }