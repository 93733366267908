@import "./stylingSchema.scss";

html {
    scroll-behavior: smooth;
    overflow-x: hidden;

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #ffffff00;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $primary-green;
        border-radius: 50px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: $secondary-green;
    }
}

.small-section-title {
    font-family: $font1;
    color: $secondary-gray;
    font-size: 23px;
    text-transform: uppercase;
    margin: 2px 0 10px 0;
}

.bottomCut {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 33vh 0 0 100vw;
    border-color: transparent transparent transparent white;
    // transform: translateY(5px);
}

.downArrow {
    width: 80px;
    position: absolute;
    bottom: 1vh;
    left: 45%;
    animation: slide 1s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;

    &:hover {
        animation: none;
        transform: translateY(-10px);
    }
}


@keyframes slide {

    0%,
    100% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(0, 10px);
    }
}


@media all and (max-width: 550px) {
    .small-section-title {
        font-size: 19px;
    }
}